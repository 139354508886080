import React, { useState } from "react";

const PersonalInfo = () => {
  const [birthDate] = useState('January 2, 1995');

  const calculateAge = () => {
    const birthYear = new Date(birthDate).getFullYear();
    const currentYear = new Date().getFullYear();
    return currentYear - birthYear;
  }

  const personalInfoContent = [
    {
      id: 1,
      colClass: "left",
      info: [
        {
          id: 1,
          name: "Birthday",
          content: "January 2, 1995",
        },
        {
          id: 2,
          name: "Age",
          content: calculateAge().toString(),
        },
        // {
        //   id: 3,
        //   name: "Location",
        //   content: "Washington, D.C.",
        // },
        {
          id: 3,
          name: "Email",
          content: (
            <>
              <a href="mailto:chris@chrisbonilla.com">chris@chrisbonilla.com</a>
            </>
          ),
        },
        {
          id: 4,
          name: "Phone",
          content: (
            <>
              <a href="tel:+1 240 670 4122">+1 240 670 4122</a>
            </>
          ),
        },
      ],
    },
    {
      id: 2,
      colClass: "right",
      info: [
        {
          id: 1,
          name: "Nationality",
          content: "USA",
        },
        {
          id: 2,
          name: "Education",
          content: "UMBC",
        },
        {
          id: 3,
          name: "Degree",
          content: "Bachelor of Science"
        },
        {
          id: 4,
          name: "Field",
          content: "Information Systems"
        },
      ],
    },
  ];
  return (
    <>
      {personalInfoContent.map((item) => (
        <div className={item.colClass} key={item.id}>
          <div className="tokyo_tm_info">
            <ul>
              {item?.info?.map((value) => (
                <li key={value.id}>
                  <span>{value.name}:</span>
                  <span>{value.content}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </>
  );
};

export default PersonalInfo;
